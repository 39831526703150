<template>
  <div class="main-container">
    <div class="top">
      <div >
        营销：
        <el-select v-model="optionValue" placeholder="请选择"  @change="handleSelect">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div style="margin-top:10px;">
        活动：
        <div class="selects">
          <el-tag
            style="margin-right:5px"
            v-for="tag in tagList"
            :key="tag.Id"
            :closable='tagList.length!=1'
            type="info"
            @close="handleClose(tag)"
            >
            {{tag.CouponName||tag.Name}}
          </el-tag>
          <el-button type="text" @click="handleEditTags">修改</el-button>
        </div>
      </div>
    </div>
    <!-- 营销概况 -->
    <div class="overview module">
      <div class="title"> 
        营销概况
      </div>
      <div class="overview-content">
        <div class="modules">
          <div>投入产出</div>
          <div>
            <div class="item">
              <span class="lable">ROI</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ROI" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.Roi||0}}</span>
            </div>
            <div class="item" v-if="optionValue==1">
              <span class="lable">营销支付金额（元）</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ActivityPayMoney" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.ActivityPayMoney||0}}</span>
            </div>
            <div class="item" v-else>
              <span class="lable">总支付金额（元）</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ActivityPayMoney" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.ActivityPayMoney||0}}</span>
            </div>
            <div class="item">
              <span class="lable">营销优惠金额（元）</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.PreferentialMoney" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.PreferentialMoney||0}}</span>
            </div>
          </div>
        </div>
        <div class="modules">
          <div>销售连带</div>
          <div>
            <div class="item">
              <span class="lable">营销支付件数</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ProductCount" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.ProductCount||0}}</span>
            </div>
            <div class="item">
              <span class="lable">营销支付订单数</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.OrderCount" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.OrderCount||0}}</span>
            </div>
            <div class="item">
              <span class="lable">连带率</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.AssociatedPurchaseRate" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.AssociatedPurchaseRate||0}}%</span>
            </div>
          </div>
        </div>
        <div class="modules">
          <div>拉新复购</div>
          <div class="echart-box">
            <echart-pie :echartPieData='echartPieData' ></echart-pie>
          </div>
        </div>
        <div class="modules" v-if="optionValue==1">
          <div>流量转化</div>
          <div class="flow-box">
            <div class="left">
              <div class="interview">
                <div class="interview-item">
                  <span class="lable">获取张数</span>
                  <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GainNumber" placement="top-start">
                    <i class="el-icon-warning icon"></i>
                  </el-tooltip>
                  <br>
                  <span class="num">{{generalData.GainNumber||0}}</span>
                </div>
                <div class="interview-item">
                  <span class="lable">获取人数</span>
                  <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GainMemberNumber" placement="top-start">
                    <i class="el-icon-warning icon"></i>
                  </el-tooltip>
                  <br>
                  <span class="num">{{generalData.GainMemberNumber||0}}</span>
                </div>
              </div>
              <div class="interview interview-buy">
                <div class="interview-item">
                  <span class="lable">使用张数</span>
                  <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UseNumber" placement="top-start">
                    <i class="el-icon-warning icon"></i>
                  </el-tooltip>
                  <br>
                  <span class="num">{{generalData.UseNumber||0}}</span>
                </div>
                <div class="interview-item">
                  <span class="lable">使用人数</span>
                  <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UseMemberNumber" placement="top-start">
                    <i class="el-icon-warning icon"></i>
                  </el-tooltip>
                  <br>
                  <span class="num">{{generalData.UseMemberNumber||0}}</span>
                </div>
              </div>
            </div>
            <div class="middle">
              <img class="bg-flow" src="https://cdn.dkycn.cn/images/melyshop/PC-AnalysisFlow.png"/>
            </div>
            <div class="right">
              使用率
              <!-- <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ROI" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip> -->
              <br>
              {{generalData.UsageRate||0}}%
            </div>
          </div>
        </div>
        
        <div class="modules" style="height:250px;" v-else>
          <div>客单/笔单价</div>
          <div style="margin-top:50px;">
            <div class="item">
              <span class="lable">营销支付人数</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.AllMemberCount" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.AllMemberCount||0}}</span>
            </div>
            <div class="item">
              <span class="lable">客单价</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UnitCustomerPrice" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.UnitCustomerPrice||0}}</span>
            </div>
            <div class="item">
              <span class="lable">笔单价</span>
              <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UnitOrderPrice" placement="top-start">
                <i class="el-icon-warning icon"></i>
              </el-tooltip>
              <br>
              <span class="num">{{generalData.UnitOrderPrice||0}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 数据趋势 -->
    <div class="trend module">
      <div class="title">
        数据趋势
        <span style="margin-left:20px;font-size:16px;">活动数据对比：</span>
        <el-switch
          v-model="compareSwitch"
          @change="handelCompareSwitch"
          active-color="#3ce66">
        </el-switch>
      </div>
      <div>
        <div class="radios" >
          <div class="label-box">
            <span>投入产出：</span>
            <span>拉新复购：</span>
            <span>销售连带：</span>
            <span>流量转化：</span>
          </div>

          <div class="check-box" v-if="!compareSwitch">
            <el-checkbox class="check-item" v-for="item in currentTrendList" :key="item.index" :label="item.label" v-model="trendCheck" @change='handleTrendCheck' :disabled="trendCheck.length>4&&!trendCheck.includes(item.label)">{{item.name}}</el-checkbox>
          </div>

          <div class="check-box" v-if="compareSwitch">
            <el-radio class="check-item" v-for="item in currentTrendList" :key="item.index" :label="item.label" v-model="trendRadio" @change='handleTrendRadio'>{{item.name}}</el-radio>
          </div>
        </div>
        <div  style="background-color: #eee;padding-bottom:10px;">
          <span  style="margin-left:75px;color:#666;font-size:14px;line-height:20px;">最多只能同时选择五个指标</span>
        </div>
      </div>
      <div class="echart-box">
        <echart-line :echartLineData='echartLineData' :lineXAxisData='lineXAxisData'></echart-line>
      </div>
    </div>

     <!-- 商品效果 -->
    <div class="effect module">
      <div class="title">
        商品效果
      </div>
      <div class="table" >
        <el-table :data="productData"   @sort-change="sortChange"  :default-sort = "defaultSort">
          <el-table-column :key="1" label="商品信息" width="300px">
             <template slot-scope="scope">
              <div class="dialog-name-content">
                <img  :src='imgUrl+scope.row.ImgUrl'/>
                <div class="right">
                  <div class="name"> {{scope.row.Name}}</div>
                  <span>￥{{scope.row.ProductPrice}}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="2" label="营销支付件数" sortable prop="ProductCount"></el-table-column>
          <el-table-column :key="3" label="营销支付人数" sortable prop="OrderCount"></el-table-column>
          <el-table-column :key="4" label="营销支付金额（元）" sortable prop="ActivityPayMoney"></el-table-column>
          <el-table-column :key="5" label="营销优惠金额（元）" sortable prop="PreferentialMoney"></el-table-column>
        </el-table>
        <el-pagination
          style="margin-top:20px;float:right;"
          v-if="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          :total="page.total"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>

    <!-- 优惠券、满减送、限时折扣弹框 -->
    <el-dialog
      title="选择优惠券"
      :visible.sync="selectionShow1"
      v-if='selectionShow1'
      width="1000px"
      class="dialog data-dialog">
      <div class="select-contanier">
        <span style="line-height:30px;" >最多只能同时选择5个优惠券</span>
        <div class="filter-container title-border">
          <!-- 关键字搜索 -->
          <div class="filter-item">
            <label class="label">关键字: </label>
             <el-input v-model="selection.searchKey" style="width: 200px;" placeholder="优惠券名称"></el-input>
          </div>
          <!--优惠券类型  -->
          <div class="filter-item" >
            <label class="label">优惠券类型: </label>
            <el-select v-model="selection.type" style="width: 200px;" placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option v-for="(item,index) in couponOptions" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            <el-button type="primary" style="width:90px;" @click="handleFilter">查询</el-button>
          </div>
        </div>
        <!-- table -->
        <div class="table-container content">
          <el-table   
            ref="compSelectTable1"
            v-if="optionValue==1"
            :data="selectTableList1" 
            v-loading="diaLoading" 
            style="width: 100%"  
            :row-key="getRowKeys1"
            @selection-change="handleSelectionChange1"
          >
            <el-table-column type="selection" prop="Id" width="55"  :selectable='selectInit' :reserve-selection="true"></el-table-column>
            <el-table-column prop="CouponName" label="优惠券"></el-table-column>
            <el-table-column prop="UseTypeValue" label="类型"></el-table-column>
            <el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
            <el-table-column prop="ValidPeriod" label="使用期限"></el-table-column>
          </el-table>

          <el-pagination v-if="selectPage.total" style="margin-top:20px;float:right;" @size-change="handleSelectSizeChange"
          @current-change="handleSelectCurrentChange" :current-page="selectPage.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="selectPage.size"
          layout="total, sizes, prev, pager, next, jumper" :total="selectPage.total">
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          已选中
          <span style="color:#F56C6C">{{multipleSelection.length}}</span>
          件
          <el-button class="button" @click="saveSelectCoupon" style="margin-left:10px;" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="选择活动"
      :visible.sync="selectionShow2"
      v-if='selectionShow2'
      width="1000px"
      class="dialog data-dialog">
      <div class="select-contanier">
        <span style="line-height:30px;" >最多只能同时选择5个活动</span>
        <div class="filter-container title-border">
          <!-- 关键字搜索 -->
          <div class="filter-item">
            <label class="label">关键字: </label>
             <el-input v-model="selection.searchKey" style="width: 200px;" placeholder="活动名称"></el-input>
          </div>
          <div class="filter-item">
            <el-button type="primary" style="width:90px;" @click="handleFilter">查询</el-button>
          </div>
        </div>
        <!-- table -->
        <div class="table-container content">

          <el-table ref="compSelectTable2" :data="selectTableList2" v-loading="diaLoading" style="width: 100%":row-key="getRowKeys2"
          @selection-change="handleSelectionChange2">
            <el-table-column type="selection" width="55"  :selectable='selectInit' :reserve-selection="true">
            </el-table-column>
            <el-table-column prop="Name" label="活动名称"></el-table-column>
            <el-table-column prop="" label="类型">
              <template>
                限时折扣
              </template>
            </el-table-column>
            <el-table-column prop="ActivityTime" label="活动时间"></el-table-column>
            <el-table-column prop="StateName" label="活动状态"></el-table-column>
          </el-table>

          <!-- <el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedAll" @change="selectCurrentAll">当前页全选</el-checkbox> -->
          <el-pagination v-if="selectPage.total" style="margin-top:20px;float:right;" @size-change="handleSelectSizeChange"
          @current-change="handleSelectCurrentChange" :current-page="selectPage.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="selectPage.size"
          layout="total, sizes, prev, pager, next, jumper" :total="selectPage.total">
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          已选中
          <span style="color:#F56C6C">{{multipleSelection.length}}</span>
          件
          <el-button class="button" @click="saveSelectCoupon" style="margin-left:10px;" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="选择活动"
      :visible.sync="selectionShow3"
      v-if='selectionShow3'
      width="1000px"
      class="dialog data-dialog">
      <div class="select-contanier">
        <span style="line-height:30px;" >最多只能同时选择5个活动</span>
        <div class="filter-container title-border">
          <!-- 关键字搜索 -->
          <div class="filter-item">
            <label class="label">关键字: </label>
             <el-input v-model="selection.searchKey" style="width: 200px;" placeholder="活动名称"></el-input>
          </div>
          <!-- 活动 -->
          <div class="filter-item">
            <label class="label">活动类型: </label>
            <el-select v-model="selection.type" style="width: 200px;" placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option v-for="(item,index) in discountOptions" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            <el-button type="primary" style="width:90px;" @click="handleFilter">查询</el-button>
          </div>
        </div>
        <!-- table -->
        <div class="table-container content">
          <!-- @select='handleSingleSelect'  -->
          <el-table  ref="compSelectTable3" :data="selectTableList3" v-loading="diaLoading" style="width: 100%" :row-key="getRowKeys3"
          @selection-change="handleSelectionChange3">
            <el-table-column type="selection" width="55"  :selectable='selectInit' :reserve-selection="true"></el-table-column>
            <el-table-column prop="Name" label="活动名称"></el-table-column>
            <el-table-column prop="ConditionTypeName" label="类型"></el-table-column>
            <el-table-column prop="ActivityTime" label="活动时间"></el-table-column>
            <el-table-column prop="StateName" label="活动状态"></el-table-column>
          </el-table>

          <el-pagination v-if="selectPage.total" style="margin-top:20px;float:right;" @size-change="handleSelectSizeChange"
          @current-change="handleSelectCurrentChange" :current-page="selectPage.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="selectPage.size"
          layout="total, sizes, prev, pager, next, jumper" :total="selectPage.total">
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          已选中
          <span style="color:#F56C6C">{{multipleSelection.length}}</span>
          件
          <el-button class="button" @click="saveSelectCoupon" style="margin-left:10px;" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import echartPie from './echartPie'
import echartLine from './echartLine'
import config from '@/config/index'
import apiList from '@/api/other'
import {timelimitdiscountlist} from '@/api/goods'
export default {
  components:{echartPie,echartLine},
  data(){
    return {
      usePersent:0,
      generalData:{},
      productData:[],
			imgUrl: config.IMG_BASE,
      optionValue:1,
      options: [
        {
          value: 1,
          label: '优惠券'
        }, {
          value: 2,
          label: '限时折扣'
        }, {
          value: 3,
          label: '满减送'
        }
      ],

      echartPieData:[
        {name: '老成交客户占比',value: '0' ,rate:'0'},{name: '新成交客户占比',value:'0' ,rate:'0' }
      ],
      compareSwitch:false,
      trendRadio:'Roi',
      trendCheck:['Roi','AllMemberCount'],
      trendList:[
        {
          id:0,
          name:'ROI',
          value:[],
          label:'Roi'
        },
        {
          id:1,
          name:'营销支付金额',
          value:[],
          label:'ActivityPayMoney'
        },
        {
          id:2,
          name:'营销优惠金额',
          value:[],
          label:'PreferentialMoney'
        },
        {
          id:3,
          name:'营销支付人数',
          value:[],
          label:'AllMemberCount'
        },
        {
          id:4,
          name:'新成交客户',
          value:[],
          label:'NewMemberCount'
        },
        {
          id:5,
          name:'老成交客户',
          value:[],
          label:'OldMemberCount'
        },
        {
          id:6,
          name:'营销支付件数',
          value:[],
          label:'ProductCount'
        },
        {
          id:7,
          name:'营销支付订单数',
          value:[],
          label:'OrderCount'
        },
        {
          id:8,
          name:'连带率',
          value:[],
          label:'AssociatedPurchaseRate'
        },
        {
          id:9,
          name:'获取张数',
          value:[],
          label:'GainNumber'
        },
        {
          id:10,
          name:'获取人数',
          value:[],
          label:'GainMemberNumber'
        },
        {
          id:11,
          name:'使用人数',
          value:[],
          label:'UseMemberNumber'
        },
        {
          id:12,
          name:'浏览量',
          value:[],
          label:'PV'
        },
        {
          id:13,
          name:'访客数',
          value:[],
          label:'UV'
        },
        {
          id:14,
          name:'转化率',
          value:[],
          label:'ConversionRate'
        }
      ],
      currentTrendList:[],
      lineXAxisData:[],
      detailData:{},
      echartLineData:[],
      page:{
        size:10,
        total:0,
        current:1
      },
      tableList:[],
      defaultSort:{prop: 'Visitors', order: 'descending'},
      selectionShow1:false,
      selectionShow2:false,
      selectionShow3:false,
      diaLoading:false,
      selection:{
        searchKey:'',
        type:null
      },
      couponOptions:[
        {
          label:'满减券',value:0
        },
        {
          label:'打折券',value:1
        },
        {
          label:'随机金额券',value:2,
        }
      ],
      discountOptions:[
        {
          label:'满N元减/送',
          value:1
        },
        {
          label:'满N件减/送',
          value:2
        }
      ],
      tagList:[],
      OrderBy:'',
      IsAsc:'',
      multipleSelection: [],
      multipleSelectionIds:[],
      selectTableList1:[],
      selectTableList2:[],
      selectTableList3:[],
      selectPage:{
        size:10,
        total:0,
        current:1
      },
      // tooltip:{}
    }
  },
  computed:{
    tooltip: function(){
      let optionValue = this.optionValue==1? '优惠券':'营销活动'
      let optionValue2 = this.optionValue==1? '优惠券':'活动'
      let optionAct = this.optionValue==1? '使用了':'参与'
      let tooltip = {
        ROI: `统计选择的${optionValue}，营销总体投入产出比（=营销支付金额/营销优惠金额）`,
        ActivityPayMoney: `统计选择的${optionValue}，支付成功并且有使用${optionValue2}的订单，订单金额总和`,
        PreferentialMoney: `统计选择的${optionValue}，支付成功的订单，通过${optionValue2}产生的优惠金额总和`,

        ProductCount: `统计选择的${optionValue}，支付成功并且${optionAct}${optionValue2}的订单，商品购买件数总和`,
        OrderCount: `统计选择的${optionValue}，支付成功并且${optionAct}${optionValue2}的订单数量`,
        AssociatedPurchaseRate: `统计选择的${optionValue}，营销支付件数/营销支付订单数`,
        
        GainNumber: `统计选择的${optionValue}，优惠券发放总数量`,
        GainMemberNumber: `统计选择的${optionValue}，获得了优惠券的人数，1人获得多张计1人`,
        UseNumber: `统计选择的${optionValue}，使用优惠券支付成功的优惠券数量`,
        UseMemberNumber: `统计选择的${optionValue}，使用优惠券成功支付订单的人数`,

        AllMemberCount: '统计选择的营销活动，成功支付订单的人数，且订单参与活动',
        UnitCustomerPrice: '统计选择的营销活动，营销支付金额/营销支付人数',
        UnitOrderPrice: '统计选择的营销活动，营销支付金额/营销支付订单数',
      }
      return tooltip
    }
  },
  created(){
    this.currentTrendList = []
    this.trendList.forEach((t)=>{
      
    })
  },
  mounted(){
    this.getDialogList1().then(res => {
      this.handleSelect(1)
      this.getData()
      this.getShopData()
    })
    this.getDialogList2()
    this.getDialogList3()
  },
  methods:{
    async getData(){
      let dataCoupon = {
          CouponIds: this.multipleSelectionIds
      }
      
      let type = this.optionValue == 2? 1:2
      let dataActivity = {
          ActivityType : type,
          ActivityIds: this.multipleSelectionIds
      }
      // 营销概况
      if(this.optionValue == 1){
        let result1 = await apiList.dataStatisCouponGeneral(dataCoupon)
        this.generalData = result1.Result
      }else{
        let result2 = await apiList.dataStatisActivityGeneral(dataActivity)
        this.generalData = result2.Result
      }
      let newPersent = 0
      let oldPersent = 0
      if(this.generalData.AllMemberCount == 0){
        newPersent = this.generalData.NewMemberCount==0?0:100
        oldPersent = this.generalData.OldMemberCount==0?0:100
      }else{
        newPersent = ((this.generalData.NewMemberCount/this.generalData.AllMemberCount)*100).toFixed(2)
        oldPersent = ((this.generalData.OldMemberCount/this.generalData.AllMemberCount)*100).toFixed(2)
      }
      this.echartPieData = [
        {name: '老成交客户占比',value: this.generalData.OldMemberCount,rate:oldPersent},{name: '新成交客户占比',value:this.generalData.NewMemberCount,rate:newPersent}
      ]
        
      // 数据趋势
      if(this.optionValue == 1){
        let result3 = await apiList.dataStatisCouponDetail(dataCoupon)
        this.detailData = result3.Result
      }else{
        let result4 = await apiList.dataStatisActivityDetail(dataActivity)
        this.detailData = result4.Result
      }
      this.dealEchartLineData()
    },
    async getShopData(){
      // 商品效果
      if(this.optionValue == 1){
        let result5 = await apiList.dataStatisCouponProduct(
          {
            CouponIds: this.multipleSelectionIds,
            Skip:this.page.size*(this.page.current-1),
            Take:this.page.size,
            OrderBy:this.OrderBy,
            IsAsc: this.IsAsc
          }
        )
        this.productData = result5.Result.Results
        this.page.total = result5.Result.Total
      }else{
        let type2 = this.optionValue == 2? 1:2
        let result6 = await apiList.dataStatisActivityProduct(
          {
            ActivityType : type2,
            ActivityIds: this.multipleSelectionIds,
            Skip:this.page.size*(this.page.current-1),
            Take:this.page.size,
            OrderBy:this.OrderBy,
            IsAsc: this.IsAsc
          }
        )
        this.productData = result6.Result.Results
        this.page.total = result6.Result.Total
      }
    },
    // 根据不同的活动数据筛选
    handelCompareSwitch(){
      this.dealEchartLineData()
    },
    dealEchartLineData(){
      let ActivityDetail = []
      if(!this.compareSwitch){
        if(this.optionValue == 1){
          ActivityDetail = this.detailData.ActivityAnalyzeCouponDetailToDay
        }else{
          ActivityDetail = this.detailData.ActivityAnalyzeActivityDetailToDay
        }
      }else{
        if(this.optionValue == 1){
          ActivityDetail = this.detailData.ActivityAnalyzeCouponDetailToCoupon
        }else{
          ActivityDetail = this.detailData.ActivityAnalyzeActivityDetailToActivity
        }
      }
      
      this.trendList.forEach((t)=>{
        let item = []
          ActivityDetail.forEach(tt=>{
              Object.keys(tt).forEach(ttt=>{//遍历一个对象的属性
                  if(t.label == ttt){
                    item.push(tt[ttt])  
                  }
              })
          })
          t.value = item
      })

      this.lineXAxisData = []
      ActivityDetail.forEach(t=>{
        let date = t.Date.trim().split(" ")[0]
        this.lineXAxisData.push(date)
      })
      this.filterCheckList()
      this.filterEchartLineData()
    },

    // 根据营销状态，筛选正确的数据趋势指标
    filterCheckList(){
      this.currentTrendList = []
      if(this.optionValue == 1){
        this.currentTrendList = this.trendList.filter((t)=> {
          return ((t.id!=12)&&(t.id!=13)&&(t.id!=14));
        });
      }else{
        this.currentTrendList = this.trendList.filter((t)=> {
          return ((t.id!=9)&&(t.id!=10)&&(t.id!=11));
        });
      }
    },
    // 根据trendCheck过滤
    filterEchartLineData(){
      this.echartLineData = []
      if(!this.compareSwitch){
        this.trendCheck.forEach((t)=>{
          let item =[]
          this.trendList.forEach(tt=>{
            if(t == tt.label)
            item = tt 
          })
          this.echartLineData.push(item)
        })
      }else{
        this.trendList.forEach(t=>{
          if(this.trendRadio == t.label){
            this.echartLineData.push(t)
          }
        })
      }
      
    },
    
    handleTrendRadio(e){
      this.filterEchartLineData()
    },
    handleTrendCheck(e){
      this.filterEchartLineData()
    },
    async getDialogList1(){
      let data = {
        KeyWord: this.selection.searchKey,
        UseType: this.selection.type,
        ValidType:0,
        Skip: this.selectPage.size*(this.selectPage.current-1),
        Take: this.selectPage.size,
      }
      let result = await apiList.activitycouponpopupList(data)
      this.selectTableList1 = result.Result.Results
      this.selectPage.total = result.Result.Total
    },
    async getDialogList2(){
      let data = {
        KeyWord: this.selection.searchKey,
        Skip: this.selectPage.size*(this.selectPage.current-1),
        Take: this.selectPage.size
      }
      let result = await apiList.activityDiscountPopupList(data)
      this.selectTableList2 = result.Result.Results
      this.selectPage.total = result.Result.Total
    },
    async getDialogList3(){
      let data = {
        KeyWords: this.selection.searchKey,
        ConditionType : this.selection.type,
        Skip: this.selectPage.size*(this.selectPage.current-1),
        Take: this.selectPage.size
      }
      let result = await apiList.activityMardownGiftPopupList(data)
      this.selectTableList3 = result.Result.Results
      this.selectPage.total = result.Result.Total
    },
    getRowKeys1(row) {
      return row.Id;
    },
    getRowKeys2(row) {
      return row.Id;
    },
    getRowKeys3(row) {
      return row.Id;
    },
    //当前页全选
    handleSelectionChange1(val) {
      this.multipleSelectionIds = []
      this.multipleSelection = val
      val.forEach(t => {
        this.multipleSelectionIds.push(t.Id)
      });
    },
    //当前页全选
    handleSelectionChange2(val) {
        this.multipleSelectionIds = []
        this.multipleSelection = val
        val.forEach(t => {
          this.multipleSelectionIds.push(t.Id)
        });
    },
    //当前页全选
    handleSelectionChange3(val) {
        this.multipleSelectionIds = []
        this.multipleSelection = val
        val.forEach(t => {
          this.multipleSelectionIds.push(t.Id)
        });
    },
    selectInit(row) {
      return (this.multipleSelectionIds.length>4&&!this.multipleSelectionIds.includes(row.Id)) ? false:true
    },
    handleSelect(e){
      this.selectPage.size = 10
      this.selectPage.current = 1
      this.page.size = 10
      this.page.current = 1
      this.multipleSelection = []
      this.multipleSelectionIds = []

      if(e==1){
        for(let i = 0;i<5;i++ ){
          if(this.selectTableList1[i]){
            this.multipleSelection.push(this.selectTableList1[i])
            this.multipleSelectionIds.push(this.selectTableList1[i].Id)
          }
        }
      }else if(e==2){
        for(let i = 0;i<5;i++ ){
           if(this.selectTableList2[i]){
            this.multipleSelection.push(this.selectTableList2[i])
            this.multipleSelectionIds.push( this.selectTableList2[i].Id)
           }
        }
      }else{
        for(let i = 0;i<5;i++ ){
          if(this.selectTableList3[i]){
            this.multipleSelection.push(this.selectTableList3[i])
            this.multipleSelectionIds.push( this.selectTableList3[i].Id)
           }
        }
      }
      this.tagList = this.multipleSelection
      this.getData()
      this.getShopData()
    },
    handleClose(tag) {
      let id = 0
      this.tagList.some((t, i)=>{
        if(t.Id== tag.Id){
          id = t.Id
          this.tagList.splice(i, 1)
          return true
        }
      })
      this.tagmultipleSelectionList = this.tagList
      this.multipleSelectionIds.splice(this.tagList.indexOf(id), 1);
      this.getData()
      this.getShopData()
    },
    // 确定选择
    saveSelectCoupon(){
      if(this.multipleSelectionIds.length){
        this.tagList = this.multipleSelection
        this.selectionShow1 = this.selectionShow2 = this.selectionShow3 = false
        this.getData()
        this.getShopData()
      }else{
        this.$message({
          showClose: true,
          type:'error',
          message:'至少选择一个活动'
        })
      }
    },
    // 查询
    handleFilter(){
      this.selectPage.size = 10
      this.selectPage.current = 1

      if(this.optionValue == 1){
        this.getDialogList1()
      } else if(this.optionValue == 2){
        this.getDialogList2()
      } else if(this.optionValue == 3){
        this.getDialogList3()
      } 
    },
    // 修改
    handleEditTags(){
      this.selectPage.size = 10
      this.selectPage.current = 1
      this.selection.searchKey = ''


      if(this.optionValue == 1){
        this.getDialogList1()
        this.selectionShow1 = true
        this.$nextTick(()=> {
          this.tagList.forEach(item => {
            this.$refs['compSelectTable1'].toggleRowSelection(item, true);
          })
        })
      } else if(this.optionValue == 2){
        this.getDialogList2()
        this.selectionShow2 = true
        this.$nextTick(()=> {
          this.tagList.forEach(item => {
            this.$refs['compSelectTable2'].toggleRowSelection(item, true);
          })
        })
      } else if(this.optionValue == 3){
        this.getDialogList3()
        this.selectionShow3 = true
        this.$nextTick(()=> {
          this.tagList.forEach(item => {
            this.$refs['compSelectTable3'].toggleRowSelection(item, true);
          })
        })
      } 
    },
    handleSizeChange(val){
      this.page.size = val
      this.getShopData()
    },
    handleCurrentChange(val){
      this.page.current = val
      this.getShopData()
    },
    handleSelectSizeChange(val){
      this.selectpage.size = val
      if(this.optionValue == 1){
        this.getDialogList1()
      } else if(this.optionValue == 2){
        this.getDialogList2()
      } else if(this.optionValue == 3){
        this.getDialogList3()
      } 
    },
    handleSelectCurrentChange(val){
      this.selectPage.current = val
      if(this.optionValue == 1){
        this.getDialogList1()
      } else if(this.optionValue == 2){
        this.getDialogList2()
      } else if(this.optionValue == 3){
        this.getDialogList3()
      } 
    },
    sortChange(column, prop, order) {
      if (column.order == "ascending") {
        // 正序
        this.IsAsc = true
        this.OrderBy = column.prop
      } else if(column.order == "descending") {
        this.IsAsc = false
        this.OrderBy = column.prop
      } else {
        this.IsAsc = ''
        this.OrderBy = ''
      }
      this.page.current = 1;
      this.getShopData()
    },
  }
}
</script>
<style lang="less" scoped>
.main-container{
  .module{
    margin-top:10px;
    padding:15px;
    background-color: #fff;
    margin:10px 0;
  }
  .icon{
    font-size: 16px;
    color:#999;
    margin-left:10px;
    cursor: pointer;
  }
  .title{
    font-size: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
  }
  .top{
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .selects{
      display: inline-block;
    }
  }
  .overview{
    .overview-content{
      .modules{
        overflow: hidden;
        width: calc(50% - 10px);
        display: inline-block;
        border: 2px solid #eee;
        margin-bottom: 10px;
        padding: 15px;
        .flow-box{
          height:200px;
          display: flex;
          flex-direction: row;
          .left{
            margin-top:10px;
            width:calc(100% - 220px);
            overflow: hidden;
            .interview{
              height: 95px;
              background-color: rgb(225, 238, 251);
              overflow: hidden;
              display: flex;
              flex-direction: row;
              .interview-item{
                padding: 20px;
                display: inline-block;
                width: 200px;
              }
              .lable{
                color:#606266;
                line-height: 20px;
                font-size: 12px;
              }
              .icon{
                font-size: 14px;
                color:#999;
                margin-left:5px;
                cursor: pointer;
              }
              .num{
                line-height: 30px;
                font-size: 18px;
              }
            }
            .interview-buy{
              margin-top:1px;
              background-color: #faf2d0;
              // background-color: rgb(225, 230, 251);
            }
          }
          .middle{
            width: 160px;
            height: 190px;
            margin-top:10px;
            .bg-flow{
              margin-left: -30px;
              height: 190px;
              object-fit: contain;
            }
          }
          .right{
            margin-top:85px;
            width: 60px;
            color:#606266;
            line-height: 20px;
            font-size: 14px;
          }

        }
        .echart-box{
          overflow: hidden;
          width: 100%;
          height:200px;
          .interview{
            margin-top:10px;
            width: 90%;
            background-color: rgb(225, 238, 251);
            overflow: hidden;
            display: flex;
            flex-direction: row;
            .left{
              height: 90px;
              width: calc(100% - 80px);
              align-items: center;
              .interview-item{
                padding: 25px;
                display: inline-block;
                width: 25%;
              }
              .lable{
                color:#606266;
                line-height: 20px;
                font-size: 12px;
              }
              .icon{
                font-size: 14px;
                color:#999;
                margin-left:5px;
                cursor: pointer;
              }
              .num{
                line-height: 30px;
                font-size: 18px;
              }
            }
            .right{
              width: 80px;
              background-color:#409EFF;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 14px;
            }
          }
          .interview-buy{
            background-color: rgb(225, 230, 251);
            .right{
              background-color:rgb(83, 107, 172);
            }
          }
        }
        .item{
          padding-top: 15px;
          display: inline-block;
          width: 33%;
        }
        .lable{
          color:#606266;
          line-height: 20px;
          font-size: 12px;
        }
        .icon{
          font-size: 14px;
          color:#999;
          margin-left:5px;
          cursor: pointer;
        }
        .num{
          line-height: 30px;
          font-size: 18px;
        }
      }
      .modules:nth-child(2n){
        margin-left:20px;
      }
    }
  }
  .trend{
    .radios{
      font-size: 14px;
      background-color: #eee;
      padding: 15px;
      display: flex;
      flex-direction: row;
      .label-box{
        span{
          display: block;
          line-height: 30px;
        }
      }
      .check-box{
        overflow: hidden;
        padding-top:8px;
        width: 600px;
        height: 120px;
        display:flex;
        flex-wrap: wrap;
        .check-item{
            width: 150px;
            height: 30px;
        }
      }
    }
    .echart-box{
      margin-top:20px;
      width: 100%;
      height: 300px;
    }
  }
  .table{
    .dialog-name-content{
      display: flex;
      flex-direction: row;
      img{
        width:70px;
        height:70px;
        object-fit:contain;
        margin-right: 10px;
      }
      .name{
        width: 170px;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient:vertical;
      }
      span{
        color: #E51C23;
        font-size:12px;
      }
    }
  }
}
.select-contanier {
  position: relative;
  overflow: hidden;
  .dialog-footer {
		margin-top: 20px;
		text-align: center;
		.button {
			width: 150px;
		}
	}
}
.dialog{
  width: 100%;
  ::v-deep .el-dialog__header {
      border-bottom: 1px solid #ddd;
  }
  ::v-deep .el-dialog__body {
      padding: 10px 20px;
  }
  ::v-deep .el-table th>.cell:first-child {
    .el-checkbox{
      display: none;
      // background-color: #edf2fc;
      // border-color: #DCDFE6;
      // cursor: not-allowed;
    }
  }
}
</style>