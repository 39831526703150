<template>
  <div ref="echartPie" style="width:100%;height:100%;">
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name:'echartPie',
  props: {
    echartPieData:{
      type:Array,
      default:[]
    },
    
  },
  data(){
    return {
      colors : ['#5793f3', '#d14a61', '#675bba'],
    }
  },
  mounted(){
    this.getEchart();
  },
  watch:{
    echartPieData(n,o){
      this.$nextTick(()=> {
        this.getEchart();
      });
    },
  },
  methods:{
    getEchart(){
        let legend=[]
        this.echartPieData.forEach(t => {
          legend.push(t.name)
        });
        let data = this.echartPieData
        let myChart =  echarts.init(this.$refs.echartPie)
        let option = {
          tooltip: {
              trigger: 'item',
              formatter:function(params){
                let data  = params.data
                let message = `${data.name}:${data.rate}%(${data.value}人)`
                return message
              }
          },
          legend: {
              type: 'scroll',
              orient: 'vertical',
              right: 10,
              data:legend,
              formatter:function(name){
                let target;
                for(let i=0;i<data.length;i++){
                    if(data[i].name===name){
                        target=data[i].rate
                    }
                }
                let arr=name+":"+target+"%";
                return arr
              },
          },
          color:['#60acfc','#5bc49f','#32d3eb','#feb64d','#ff7c7c','#9287e7','#FFEE58','#e57373','#BA68C8','#7986CB','#4FC3F7','#4DB6AC','#AED581','#FFF176','#FFB74D','#F06292','#9575CD','#4FC3F7','#FF8A65','#DCE775'],  
          series: [
            {
              name:'',
              type:'pie',
              radius: ['50%', '90%'],
              avoidLabelOverlap: false,
              label: {
                  normal: {
                      show: false,
                  },
                  emphasis: {
                      show: false
                  }
              },
              labelLine: {
                  normal: {
                      show: false
                  }
              },
              data:this.echartPieData
            }
          ]
        };
        myChart.setOption(option);
    }
  }
}
</script>